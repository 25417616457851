@keyframes colorCycle {
    0% { background-color: #C3DAFE; }   /* bg-blue-200 */
    33% { background-color: #FDC2D2; } /* bg-pink-200 */
    66% { background-color: #FDE8D7; } /* bg-orange-200 */
    100% { background-color: #C3DAFE; } /* return to bg-blue-200 */
}

@keyframes colorCycleStrong {
    0% { background-color: #8CFFBF; } /* bg-green-300 */
    33% { background-color: #81E6D9; } /* bg-teal-300 */
    66% { background-color: #D8B4FE; }   /* bg-purple-300 */
    100% { background-color: #8CFFBF; } /* return to bg-purple-300 */
}

@keyframes lineColorCycleStrong {
    0%, 100% { background: linear-gradient(90deg, #8CFFBF, #81E6D9); } /* bg-green-300 */
    25% { background: linear-gradient(90deg, #81E6D9, #D8B4FE); } /* bg-teal-300 */
    50% { background: linear-gradient(90deg, #D8B4FE, #FDC2D2); }   /* bg-pink-300 */
    75% { background: linear-gradient(90deg, #FDC2D2, #8CFFBF); } /* bg-purple-300 */
}

@keyframes gradientLoop {
    0%, 100% {
        background: linear-gradient(90deg, #8CFFBF, #81E6D9); /* bg-green-300 */
    }
    12.5% {
        background: linear-gradient(90deg, #8CFFBF, #D8B4FE); /* Mid between green and teal */
    }
    25% {
        background: linear-gradient(90deg, #81E6D9, #D8B4FE); /* bg-teal-300 */
    }
    37.5% {
        background: linear-gradient(90deg, #D8B4FE, #FDC2D2); /* Mid between teal and pink */
    }
    50% {
        background: linear-gradient(90deg, #D8B4FE, #FDC2D2);   /* bg-pink-300 */
    }
    62.5% {
        background: linear-gradient(90deg, #FDC2D2, #8CFFBF); /* Mid between pink and green */
    }
    75% {
        background: linear-gradient(90deg, #FDC2D2, #8CFFBF); /* bg-purple-300 */
    }
    87.5% {
        background: linear-gradient(90deg, #8CFFBF, #81E6D9); /* Returning back to green */
    }
}

@keyframes textColorCycleSoft {
    0% { color: #BFDBFE; }   /* bg-blue-300 */
    33% { color: #FBB6CE; } /* bg-pink-300 */
    66% { color: #8CFFBF; } /* bg-green-300 */
    100% { color: #BFDBFE; }
}

@keyframes gradientBgCycle {
    0%, 100% {
        background: linear-gradient(90deg, #8CFFBF, #81E6D9); /* Start and end with bg-green-300 to teal component */
    }
    25% {
        background: linear-gradient(90deg, #81E6D9, #D8B4FE); /* Transition from teal component to bg-teal-300 */
    }
    50% {
        background: linear-gradient(90deg, #D8B4FE, #FDC2D2); /* Transition from bg-teal-300 to bg-pink-300 */
    }
    75% {
        background: linear-gradient(90deg, #FDC2D2, #8CFFBF); /* Transition from bg-pink-300 to bg-green-300 */
    }
}

@keyframes textColorCycle {
    0% { color: #93C5FD; }   /* bg-blue-400 */
    33% { color: #F687B3; } /* bg-pink-400 */
    66% { color: #66CCA6; } /* bg-green-400 */
    100% { color: #93C5FD; } /* return to bg-blue-400 */
}

@keyframes textColorCycleReverse {
    0% { color: #8CFFBF; } /* bg-green-300 */
    33% { color: #81E6D9; } /* bg-teal-300 */
    66% { color: #D8B4FE; }   /* bg-purple-300 */
    100% { color: #8CFFBF; } /* return to bg-purple-300 */
}

.gradientLoop {
    animation: gradientBgCycle 12s infinite ease-in-out 0s alternate;
}

.lineColorCycleStrong {
    animation: lineColorCycleStrong 20s infinite alternate;
    display: inline-block;
    padding: 16px; /* Adjust based on your content's padding */
    background: linear-gradient(90deg, red, blue); /* Default gradient */
    background-clip: content-box, border-box;
    box-shadow: inset 0 0 0 3px white; /* Adjust this value to your desired border width */
    /*animation: animateGradient 5s infinite;  Duration can be adjusted */
}

.colorCycleStrong {
    animation: colorCycleStrong 20s infinite;
}

.colorCycleStrongHover:hover {
    animation: colorCycleStrong 20s infinite;
}

.colorCycle {
    animation: colorCycle 20s infinite;
}

.textColorCycle {
    animation: textColorCycle 20s infinite;
}

.textColorCycleSoft {
    animation: textColorCycleSoft 20s infinite;
}

.textColorCycleReverse {
    animation: textColorCycleReverse 20s infinite;
}

@keyframes colorCycleReverse {
    0% { background-color: #E9D5FF; }   /* bg-purple-200 */
    33% { background-color: #BEE3F8; } /* bg-teal-200 */
    66% { background-color: #B2FFD6; } /* bg-green-200 */
    100% { background-color: #E9D5FF; } /* return to bg-purple-200 */
}


.colorCycleReverse {
    animation: colorCycleReverse 30s infinite;
}


